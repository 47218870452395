.LogoSectionHero{
    width: 25%;
    margin: 30px 0;
}

.titleCards{
    font-size: 30px;
}

.triangulos{
    position: absolute;
     right: 1%;
     top: 20%
}


.btnBookNow{
    font-size: 30px
}

.trianguloUno{
    font-size: 25px;
    color: #fff741
}

.triangulo{
    font-size: 25px;
    color: #3daab7;
    margin-top: -3px
}

.justifyTexto{
    text-align: justify;
}

.tituloTourCheckout{
    padding: 5px;
    font-size: 32px;
    line-height: 1
}

.titlePriceDetails{
    font-size: 35px;
    margin-bottom: 10px;
}

.meetingPoint{
    display: flex;
    justify-content: space-between
}

@media (max-width: 1300px) {
    .cardReviews{
        flex-direction: column;
    }
  }

  @media (max-width: 900px) {
    .titleCards{
        font-size: 20px;
    }
    .btnBookNow{
        font-size: 16px
    }
  }

  @media (max-width: 850px) {
    .meetingPoint{
        flex-direction: column;
    }
  }

  @media (max-width: 770px) {
    .triangulos{
        position: absolute;
         right: 1%;
         top: 65%;
         z-index: 9999;
    }
    .tituloTourCheckout{
        font-size: 20px;
    }

    .titlePriceDetails{
        font-size: 30px;
    }
  }

  @media (max-width: 470px) {
    .triangulos{
        position: absolute;
         right: 1%;
         top: 50%;
         z-index: 9999;
    }
  }

  @media (max-width: 500px) {
    .titleCards{
        font-size: 18px;
    }

    .titlePriceDetails{
        font-size: 25px;
    }
  }
