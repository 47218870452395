.almostReady{
    display: flex;
    justify-content: center;
    font-size: 50px
}

.LogoCheckout{
    width: 22%;
    margin: 100px;
  }

.personalDetailsPayment{
    border: 1px solid white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
}

.perosnalDetailsPaymentItems{
    width: 50%;
    padding: 10px;
}

.widthIcon{
    width: 40px;
}

.titlePersonalDetails{
    font-size: 45px;
    line-height: 1;
}

.youtTour{
    font-size: 25px;
    font-weight: 700;
}

.priceDetails{
    display: flex;
}

.priceDetailsItems{
    width: 50%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.borderRight{
    border-left: 1px solid white;
}

.totalCheckout{
    padding: 10px 30%;
}

.formFlex{
    display: flex;
  }

  .formFlexItems{
    width: 50%;
    padding: 0 20px;
  }

.homeCards{
    margin-right: -15px;
    margin-top: -10px;
}

.posicionCaminoPies{
    top: -150px;
}

.includes {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
  }
  
  .includes-item {
    display: flex;
    justify-content: start;
    align-items: center;
  }

@media (max-width: 1150px) {
    .homeCards{
        margin-right: 0px;
    }
  }

@media (max-width: 1030px) {
    .personalDetailsPayment{
        flex-direction: column ;
    }

    .perosnalDetailsPaymentItems{
        width: 100%;
    }

    .titlePersonalDetails{
        font-size: 35px;
        text-align: center;
    }

    .almostReady{
        margin-top: 20px;
    }
  }

  @media (max-width: 768px) {
    .almostReady{
        font-size: 26px
    }

      .titlePersonalDetails{
        font-size: 30px;
    }

    .youtTour{
        font-size: 18px;
    }

    .formFlex{
        flex-direction: column;
      }
    
      .formFlexItems{
        width: 100%;
        padding: 0 20px;
      }
  }

  .triangulosCheck{
    position: absolute;
     bottom: 30px;
     right: 50px;
     transform: rotate(270deg);
}

  .trianguloUnoCheck{
    font-size: 25px;
    color: #fff741
    }

    .trianguloChek{
        font-size: 25px;
        color: #3daab7;
        margin-top: -3px
    }

    .trianguloCheck{
        font-size: 25px;
        color: #3daab7;
        margin-top: -3px
    }

  @media (max-width: 640px) {
    .imgCheckout{
        flex-direction: column;
    }

    .widthImgCheckout{
        width: 50%;
        margin-bottom: 10px;
    }

    .widthIcon{
        width: 25px;
    }

    .priceDetails{
        flex-direction: column;
    }
    
    .priceDetailsItems{
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .borderRight{
        border-top: 1px solid white;
        border-left: none;
    }
  }

  @media (max-width: 500px) {
    .Logowidth{
        width: 40%;
      }
      .includes {
        display: grid;
        grid-template-columns: repeat(1, 1fr); 
        gap: 20px; 
      }
  }


