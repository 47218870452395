.datePickerPay .react-datepicker {
    width: 300px;
    height: 160px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    background-color: #232323;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    z-index: 9999 !important;
    position: relative;
    top: -120px;
    display: flex;
    flex-direction: column;
  }

  .datePickerPay .react-datepicker__navigation--previous{
    position: relative;
    width: 40%;
    margin: 0;
    padding: 0;
  }

  .datePickerPay .react-datepicker__navigation--next{
    position: absolute;
    right: 0;
    top: 0;
    width: 40%;
    margin: 0;
    padding: 0;
  }
  
  .datePickerPay .react-datepicker__input-container > input {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    background-color: #232323;
    color: #fff;
    border-radius: 30px;
    border: 1px solid #374151
  }
  
  .datePickerPay .react-datepicker__month-container {
    position: relative;
    top: -40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .datePickerPay .react-datepicker__month {
    width: 100%;
    margin: 10px;
    padding: 10px;
    background-color: #232323;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    cursor: pointer;
    z-index: 9999;
  }

  .datePickerPay .react-datepicker__year {
    color: #fff
  }
  
  