
.Font500 {
    font-size: 34px;
    font-weight: 700;
  }

.LogoListing{
  position: absolute;
  width: 20%;
  bottom: 30px;
  left: 43%
}

@media (max-width: 630px) {
    .Font500 {
      font-size: 24px;
    }
  }

  @media (max-width: 450px) {
    .Font500 {
      font-size: 18px;
    }
  }