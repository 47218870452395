.FontTitle{
    font-size: 50px;
    padding-bottom: 25px;
}

@media (max-width: 680px) {
    .FontTitle {
      font-size: 30px;
      padding-bottom: 0px;
    }
  }

  @media (max-width: 420px) {
    .FontTitle {
      font-size: 20px;
    }
  }